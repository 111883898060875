import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { fontWeight, Title56 } from './styles/TextStyles'
import { themes } from '../components/styles/ColorStyles'
import HeaderIcon from './HeaderIcon'

const StyledHeader = styled(Title56)`
  font-weight: ${fontWeight.bold};
  transition: 500ms;
  margin: 0;
  background-color: ${themes.light.neutral9};
  background-image: linear-gradient(
    45deg,
    rgba(41, 49, 231, 1) 0%,
    rgba(145, 38, 240, 1) 22%,
    rgba(200, 25, 241, 1) 46%,
    rgba(139, 54, 252, 1) 76%,
    rgba(230, 29, 112, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400%;
  animation: bgAnimation 2.5s infinite alternate linear;

  @media (max-width: 800px) {
    font-size: 40px;
  }
`

const HeaderContent = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 72px;

  @media (max-width: 800px) {
    align-items: flex-start;
    width: 100%;
  }
`

const HeaderWrapper = styled.div`
  position: fixed;
  width: 75%;
  padding: 40px 0px 8px 0;
  z-index: 100;
  background-color: rgba(250, 250, 252, 0.96);

  @media (max-width: 800px) {
    width: 90%;
  }
`

const Tabs = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`

const SeeYa = styled.div`
  &:hover {
    cursor: ne-resize;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const Header = () => (
  <HeaderWrapper>
    <HeaderContent>
      <Link to="/">
        <StyledHeader>sqott</StyledHeader>
      </Link>
      <Tabs>
        <Link to="/">
          <HeaderIcon
            icon={require('../images/icon/home.svg').default}
            hoverText="HOME"
          />
        </Link>
        <Link to="/work">
          <HeaderIcon icon={require('../images/icon/work.svg').default} hoverText="WORK" />
        </Link>
        <SeeYa>
          <Link
            href="https://instagram.com/sqott"
            target="_blank"
            rel="noreferrer"
          >
            <HeaderIcon icon={require('../images/icon/insta.svg').default} hoverText="INSTA"/>
          </Link>
        </SeeYa>
        <SeeYa>
          <Link
            href="https://twitter.com/sqottgary"
            target="_blank"
            rel="noreferrer"
          >
            <HeaderIcon icon={require('../images/icon/twit.svg').default} hoverText="TWIT"/>
          </Link>
        </SeeYa>
        <SeeYa>
          <Link
            href="https://linkedin.com/in/sqott"
            target="_blank"
            rel="noreferrer"
          >
            <HeaderIcon icon={require('../images/icon/in.svg').default} hoverText="JOB" />
          </Link>
        </SeeYa>
      </Tabs>
    </HeaderContent>
  </HeaderWrapper>
)

export default Header
