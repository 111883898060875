export const themes = {
  light: {
    neutral1: '#FFFFFF',
    neutral2: '#FAFAFC',
    neutral3: '#F0F0F5',
    neutral4: '#D9D9E2',
    neutral5: '#BEBECC',
    neutral6: '#9B9BAD',
    neutral7: '#707084',
    neutral8: '#424253',
    neutral9: '#17171F',
  },
  dark: {
    neutral1: '#140D26',
    neutral2: '#1E1536',
    neutral3: '#271C42',
    neutral4: '#443666',
    neutral5: '#726496',
    neutral6: '#8376A3',
    neutral7: '#988BB5',
    neutral8: '#C7BFDB',
    neutral9: '#FFFFFF',
  },
}


