import React from 'react'
import styled from 'styled-components'
import Header from './header'
import '../layouts/index.css'

const LayoutPage = styled.div`
  padding: 0px 200px;
  display: flex;
  flex-direction: column;
  transform-origin: 50% 20px;

  @media (max-width: 800px) {
    padding: 0px 24px;
  }
`

const Layout = ({ children }) => (
  <LayoutPage>
    <Header />
    {children}
  </LayoutPage>
)

export default Layout
