import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Body16 } from './styles/TextStyles'

const Wrap = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: ${themes.light.neutral3};
  margin: 0px 8px 0px 8px;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: 0.3s;
  position: relative;

  &:hover {
    background: ${themes.light.neutral4};
  }

  &:active{
    background: ${themes.light.neutral5};
  }
`

const HoverWrap = styled.div`
  opacity: 0;
  transition: 0.2s;
`

const Hold = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & ${Wrap}:hover + ${HoverWrap} {
    opacity: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 4px;
    margin-top: 16px;
    padding: 2px 8px;
    background-color: ${themes.light.neutral4};
  }
`


const Icon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 25%;
  left: 25%;
  margin: 0 auto;
`

const BoxText = styled(Body16)`
  font-weight: ${fontWeight.bold};
  color: ${themes.light.neutral9};
`

const HeaderIcon = (props) => (
  <Hold>
    <Wrap>
      <Icon src={props.icon} />
    </Wrap>
    <HoverWrap>
      <BoxText>{props.hoverText}</BoxText>
    </HoverWrap>
  </Hold>
)

export default HeaderIcon
