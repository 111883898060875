import styled from 'styled-components'

export const Title72 = styled.h1`
    font-size: 4.5rem;
    line-height: 100%;
    letter-spacing: -4px; 
`

export const Title64 = styled.h1`
    font-size: 3.75rem;
    line-height: 100%;
`

export const Title56 = styled.h1`
    font-size: 3.5rem;
    line-height: 100%;
`

export const Title48 = styled.h1`
    font-size: 3rem;
    line-height: 100%;
`

export const Title40 = styled.h1`
    font-size: 2.5rem;
    line-height: 100%;
`

export const Title32 = styled.h2`
    font-size: 2rem;
    line-height: 125%;
`

export const Title28 = styled.h2`
    font-size: 1.75rem;
    line-height: 125%;
`

export const Title24 = styled.h3`
    font-size: 1.5rem;
    line-height: 125%;
`

export const Title20 = styled.h3`
    font-size: 1.25rem;
    line-height: 125%;
`

export const Body18 = styled.p`
    font-size: 1.125rem;
    line-height: 150%;
`

export const Body16 = styled.p`
    font-size: 1rem;
    line-height: 150%;
`

export const fontWeight = {
    extraBold: "700",
    bold: "700",
    semiBold: "600",
    medium: "600",
    regular: "400",
    thin: "300",
}